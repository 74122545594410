import { GetStaticPropsResult } from 'next';
import { LayoutProps } from 'components/layout';
import { getMenus } from 'lib/get-menus';
import {DrupalNode, DrupalTaxonomyTerm} from 'next-drupal';
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { drupal } from '../lib/drupal';
import {testApiCompatibility} from "../lib/test-api-compatibility";
import {ENTITY_TYPES, REVALIDATE_TTL} from './[...slug]';
import {getWebforms} from "../lib/get-webforms";
import getParagraphs from "../lib/get-paragraphs";
import {getBlurMap} from "../lib/get-blur-map";
import {BlurMap} from "../types/hygiena-types";
import {NodeLandingPage} from "../components/templates/nodes/node--landing_page";
import {getAlertBanner} from "../lib/get-alert-banner";
import {getDictionary} from "../lib/dictionaries";

interface IndexPageProps extends LayoutProps {
  entity?: DrupalNode,
  blurMap?: BlurMap,
  metatag?: any,
  entityInfo?: {
    id?: string,
    type?: string,
  }
}

export default function IndexPage({ entity }: IndexPageProps) {
  return (
      <NodeLandingPage key={`index-${entity?.id}`} node={entity as DrupalNode} />
  );
}

export async function getStaticProps(context): Promise<GetStaticPropsResult<IndexPageProps>> {
  const start = performance.now();
  const NEXT_BUILD_MODE = process.env?.["NEXT_BUILD_MODE"] ?? false;
  if (process.env.NODE_ENV == 'development') {
    try {
      await testApiCompatibility(ENTITY_TYPES, drupal);
    }
    catch (error) {
      console.log(error);
    }
  }

  let newsletterPad = false;
  const params = new DrupalJsonApiParams();

  if (context?.preview) {
    params.addCustomParam({'resourceVersion': context?.previewData?.resourceVersion});
  }

  const entity = await drupal.getResourceFromContext<DrupalNode>(
    'node--landing_page',
    context,
    {
      params: params.getQueryObject(),
    }
  );

  if (entity?.type === 'node--landing_page' && entity?.field_paragraphs) {
    await getParagraphs(entity, context);
  }

  newsletterPad = entity?.["field_landing_page_b_pad"] ?? false;

  if (!NEXT_BUILD_MODE) {
    const end = performance.now();
    console.log(`Slug / took ${((end - start) / 1000).toFixed(2)} seconds to build.`)
  }

  return {
    props: {
      entity: entity,
      webforms: await getWebforms(context),
      entityInfo: {
        id: entity.id,
        type: entity.type
      },
      alertBanner: await getAlertBanner(context),
      menus: await getMenus(context),
      blurMap: await getBlurMap(),
      metatag: entity?.metatag,
      newsletterPad: !newsletterPad,
      dictionary: await getDictionary(context?.locale),
      translatedUrls: {
        en: '/en',
        es: '/es',
        de: '/de',
        fr: '/fr',
        'pt-br': '/pt-br',
      }
    },
    revalidate: parseInt(REVALIDATE_TTL),
  };
}
