import {DrupalParagraph} from "next-drupal";
import {ParagraphSectionMap} from "../types/hygiena-types";

const ONE_COL = 'layout_onecol';
const TWO_COL = 'layout_twocol_section';
const THREE_COL = 'layout_threecol_section';
const FOUR_COL = 'layout_fourcol_section';

const sectionTypes = [
  'paragraph--section',
];

/**
 * Returns an object containing sections and a section map.
 *
 * @param entities
 */
export function getParagraphSections(entities?: DrupalParagraph[]): ParagraphSectionMap {
  if (!Array.isArray(entities)) return {sectionMap: {}, sections: []};
  const sectionMap = {};
  const sections = entities.filter(paragraph => {
    return sectionTypes.includes(paragraph.type);
  });
  const paragraphs = entities.filter(paragraph => {
    return !sectionTypes.includes(paragraph.type);
  });

  // Create a map of sections, by setting the amount of regions per section.
  for (const section of sections) {
    const layout = section?.behavior_settings?.layout_paragraphs?.layout ?? 'layout_onecol';
    sectionMap[section.id] = {};

    if ([ONE_COL, TWO_COL, THREE_COL, FOUR_COL].includes(layout)) {
      sectionMap[section.id].first = [];
    }
    if ([TWO_COL, THREE_COL, FOUR_COL].includes(layout)) {
      sectionMap[section.id].second = [];
    }
    if ([THREE_COL, FOUR_COL].includes(layout)) {
      sectionMap[section.id].third = [];
    }
    if ([FOUR_COL].includes(layout)) {
      sectionMap[section.id].fourth = [];
    }
  }
  // Map the paragraphs to their section parent.
  for (const paragraph of paragraphs) {
    const region = paragraph?.behavior_settings?.layout_paragraphs?.region;
    const parent_uuid = paragraph?.behavior_settings?.layout_paragraphs?.parent_uuid;
    if (sectionMap[parent_uuid]) {
      if (sectionMap[parent_uuid][region]) {
        sectionMap[parent_uuid][region].push(paragraph);
      }
      else if (Object.keys(sectionMap[parent_uuid]).length === 1) {
        sectionMap[parent_uuid].first.push(paragraph);
      }
    }
  }

  return {sections, sectionMap};
}