import {getParagraphSections} from "../../../lib/get-paragraph-sections";
import dynamic from "next/dynamic";

const RenderSections = dynamic(() => import('../sections/section-regions').then((mod) => mod.RenderSections));

export function NodeLandingPage({node, ...props}) {
  const {sections, sectionMap} = getParagraphSections(node?.field_paragraphs)

  return (
    <article className="mx-auto landing-page" {...props}>
      <RenderSections sections={sections} sectionMap={sectionMap}/>
    </article>
);
}
